(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAuth.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl(Auth, FormControl, _, $location, $state, $scope, $mdDialog, $cookies, $document, $translate, moment, $rootScope, SessionService) {
    var vm = this
      , formControl = new FormControl()
      , firstLoginScreenLoadCookieKey = 'firstLoginScreenLoad';

    vm.session = {
      identifier: '',
      password: ''
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      setAppTransitioning();
      vm.loginForm.$setSubmitted();
      $location.setNextUrl($state.get('companySelector').url);

      formControl
        .process(Auth.login(vm.session))
        .then(function () {
          var activeSession = Auth.getSession();
          return SessionService.setSessionTimeout(activeSession.sessionTime);
        })
        .catch(clearAppTransitioning);

      function setAppTransitioning() {
        $rootScope.appIsTransitioning = true;
      }

      function clearAppTransitioning() {
        $rootScope.appIsTransitioning = false;
      }
    };

    vm.errorIsLoginLimit = function errorIsLoginLimit() {
      return _.get(vm.getServerValidation(), 'identifier.loginLimit', false);
    };

    // $scope.$on('$stateChangeSuccess', checkFirstLoginScreenLoad);

    function checkFirstLoginScreenLoad() {
      if (!_.isUndefined($cookies.get(firstLoginScreenLoadCookieKey))) {
        return;
      }

      $cookies.put(firstLoginScreenLoadCookieKey, 1, {expires: moment().add(1, 'Y').toDate()});
      $mdDialog.show(
        $mdDialog
          .alert({focusOnOpen: false})
          .parent(angular.element($document.body))
          .clickOutsideToClose(true)
          .content($translate.instant('auth.login.firstLoginDialog.content'))
          .ok($translate.instant('passwordResetConfirmDialog.button.ok'))
      );
    }
  }
}());
